// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:01068c20-aefc-4f3f-b93b-c50d7ce83a1b",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_vbD2HKDES",
    "aws_user_pools_web_client_id": "427dk4tps80uti9gn2scq43luq",
    "aws_cloud_logic_custom": [
        {
            "name": "gdpliveapi",
            "endpoint": "https://xpr9prf0gd.execute-api.ap-southeast-2.amazonaws.com/Prod",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
